<script lang="ts">
  import Global from "./components/Global.svelte";
  import AppContext from "./context/AppContext.svelte";
  import ThemeContext from "./context/ThemeContext.svelte";
  import Router from "./screens/Router.svelte";

  let isLoading = true;
</script>

<ThemeContext>
  <AppContext bind:isLoading>
    <Global />
    <Router {isLoading} />
  </AppContext>
</ThemeContext>
