<script lang="ts">
  export let style: string = "";
</script>

<svg
  {style}
  width="74"
  height="74"
  viewBox="0 0 74 74"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <rect
    opacity="0.12"
    width="74"
    height="74"
    rx="37"
    style="fill:var(--link-theme-primary);"
  />
  <path
    d="M30.8914 25.9997H43.1096L45.1629 20.5244C45.3215 20.1018 45.2619 19.6288 45.0053 19.2585C44.7486 18.8873 44.326 18.6663 43.875 18.6663H40.4265C39.6859 17.5618 38.4273 16.833 37 16.833C35.5728 16.833 34.3142 17.5618 33.5735 18.6663H30.125C29.674 18.6663 29.2514 18.8873 28.9957 19.2585C28.739 19.6288 28.6794 20.1018 28.838 20.5244L30.8914 25.9997ZM54.3617 46.9638C54.1875 51.6572 50.365 55.333 45.6534 55.333H28.3467C23.635 55.333 19.8125 51.6572 19.6384 46.9638C19.3359 38.6863 25.12 32.1413 28.9242 28.7497H45.0759C48.88 32.1413 54.6642 38.6863 54.3617 46.9638ZM41.5834 38.3747H40.9444C40.8179 37.887 40.6282 37.425 40.3816 36.9997H41.5834C42.3424 36.9997 42.9584 36.3846 42.9584 35.6247C42.9584 34.8648 42.3424 34.2497 41.5834 34.2497H35.625H32.4167C31.6577 34.2497 31.0417 34.8648 31.0417 35.6247C31.0417 36.3846 31.6577 36.9997 32.4167 36.9997H35.625C36.6379 36.9997 37.5152 37.557 37.9928 38.3747H32.4167C31.6577 38.3747 31.0417 38.9898 31.0417 39.7497C31.0417 40.5096 31.6577 41.1247 32.4167 41.1247H37.9928C37.5161 41.9423 36.6389 42.4997 35.625 42.4997H32.4167C31.8612 42.4997 31.3598 42.8343 31.1462 43.3485C30.9326 43.8628 31.0509 44.454 31.4441 44.8473L36.9441 50.3473C37.2127 50.6158 37.5647 50.7497 37.9167 50.7497C38.2687 50.7497 38.6207 50.6158 38.8893 50.3473C39.4264 49.811 39.4264 48.9393 38.8893 48.403L35.7359 45.2497C38.2449 45.2002 40.3404 43.4594 40.9454 41.1256H41.5834C42.3424 41.1256 42.9584 40.5105 42.9584 39.7506C42.9584 38.9907 42.3424 38.3747 41.5834 38.3747Z"
    style="fill:var(--link-theme-primary);"
  />
</svg>
