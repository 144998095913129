<script lang="ts">
  export let style: string = "";
</script>

<svg
  {style}
  width="36"
  height="36"
  viewBox="0 0 36 36"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <rect
    opacity="0.12"
    width="36"
    height="36"
    rx="18"
    style="fill:var(--link-theme-primary);"
  />
  <path
    d="M14.0417 26.3337C13.9384 26.3337 13.8346 26.3082 13.7396 26.2557C13.4917 26.1187 13.3667 25.832 13.4359 25.557L14.9079 19.667H12.7917C12.5954 19.667 12.4109 19.5749 12.2925 19.4182C12.1746 19.2616 12.1367 19.0587 12.1904 18.8703L14.6904 10.1203C14.7675 9.85199 15.0125 9.66699 15.2917 9.66699H21.125C21.3259 9.66699 21.5146 9.76366 21.6321 9.92658C21.7496 10.0895 21.7817 10.2991 21.7179 10.4895L20.3254 14.667H24.0417C24.2921 14.667 24.5184 14.8166 24.6167 15.047C24.715 15.2774 24.6663 15.5441 24.4925 15.7245L14.4925 26.1412C14.3717 26.2674 14.2075 26.3337 14.0417 26.3337Z"
    style="fill:var(--link-theme-primary);"
  />
</svg>
