<script>
  import { getContext, onMount } from "svelte";
  import Button from "../components/Button.svelte";
  import DottedLine from "../components/DottedLine.svelte";
  import Input from "../components/Input.svelte";
  import Loader from "../components/Loader.svelte";
  import { agent_code_store, mobile_store, source_store } from "../stores";
  import { Post, secToTime } from "../utils";

  // PROPS
  export const setRoute = () => {};

  // STATES
  let otp = "";
  let isOTP = true;
  let error = "";
  let mobile = "";
  let agentCode = "";
  let source = "";
  let isLoading = false;
  let timer = 180;
  let timerInterval = "";

  function startInterval() {
    const int = setInterval(() => {
      if (timer == 0) {
        clearInterval(int);
        return;
      }
      timer = timer - 1;
    }, 1000);

    return int;
  }

  mobile_store.subscribe((e) => {
    mobile = e;
  });

  agent_code_store.subscribe((e) => {
    agentCode = e;
  });

  source_store.subscribe((e) => {
    source = e;
  });

  let config = null;
  const configContext = getContext("config");
  configContext((e) => {
    config = e;
  });

  const creditLink = async (token) => {
    if (timerInterval) clearInterval(timerInterval);

    const resp = await Post("/freedom/creditLink", token, null, {
      source,
      dsaID: config.dsaID,
      redirectURL: window.location.href,
    });
    if (resp.status) {
      document.location.replace(resp.data.url);
    } else {
      error = resp.error;
    }
  };

  const submitOTP = async () => {
    error = "";
    if (otp.length !== 6) {
      isOTP = false;
      return;
    }
    isOTP = true;
    const resp = await Post("/freedom/signinVerify", null, null, {
      mobile,
      otp,
    });
    if (resp.status) {
      creditLink(resp.data.token);
    } else {
      isOTP = false;
    }
  };

  const requestOTP = async () => {
    const resp = await Post("/freedom/signin", null, null, {
      mobile,
    });
  };

  const resendOTP = (e) => {
    let disabled = timer != 0;
    if (disabled) {
      return;
    }
    isOTP = true;
    if (timer > 120) return;
    timer = 180;
    requestOTP();
    timerInterval = startInterval();
    otp = "";
  };

  onMount(async () => {
    requestOTP();
    timerInterval = startInterval();
  });
</script>

<main class="container" id="link-root">
  <Loader {isLoading} />
  <!-- <div style="margin-top:20%" /> -->
  <DottedLine />
  <Input
    style="width: 100%;letter-spacing:10px"
    label="Verify OTP"
    sub={`OTP sent to ${mobile}`}
    placeholder="eg: 123456"
    autofocus
    onInput={(e) => {
      const isNumber = !isNaN(Number(e.target.value));
      const hasMathSymbols = /[\.e]/g.test(e.target.value);
      if (isNumber && !hasMathSymbols) {
        return otp;
      } else {
        otp = otp.slice(0, otp.length - 1);
        return otp;
      }
    }}
    type="tel"
    maxLength="6"
    bind:value={otp}
  />

  <div class="row">
    <div class="timer">{secToTime(timer)}</div>
    <div class={`resend ${timer == 0 ? "active" : ""}`} on:click={resendOTP}>
      Resend OTP
    </div>
  </div>

  <div class="otp-error">
    {!isOTP && !error ? "Incorrect OTP" : ""}
    {error ? error : ""}
  </div>

  <Button style="auto;align-self:center;margin-bottom:10%" on:click={submitOTP}>
    Verify OTP
  </Button>
</main>

<style lang="scss">
  .partner-logo {
    width: 30%;
  }
  .row {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    justify-content: space-between;
    font-size: 15px;
    font-weight: 500;
    .resend {
      color: var(--link-theme-primary);
      opacity: 0.5;
      cursor: pointer;
    }

    .active {
      opacity: 1;
    }

    .timer {
      color: var(--link-theme-secondary);
    }
  }
  .otp-error {
    height: 30px;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    color: var(--link-theme-errorColor);
    margin-top: auto;
    margin-bottom: 30%;
    width: 100%;
  }
</style>
