<script>
  import { onMount, setContext, getContext } from "svelte";
  import * as Sentry from "@sentry/svelte";
  import { writable } from "svelte/store";
  import { Get } from "../utils";
  import {
    partner_code_store,
    frame_store,
    mobile_store,
    client_api_key_store,
    source_store,
    agent_code_store,
    route_store,
  } from "../stores";
  import { ROUTES_CONST } from "../constants";
  const themeContext = getContext("theme");

  let theme = {};
  themeContext.theme.subscribe((e) => {
    theme = e;
  });

  const Config = writable(null);

  setContext("config", Config.subscribe);
  export let isLoading = true;
  const init = async () => {
    isLoading = true;
    const queryString = document.location.search;
    const urlParams = new URLSearchParams(queryString);
    let partnerCode = urlParams.get("partnerCode");
    let agentCode = urlParams.get("agentCode");
    let mobile = urlParams.get("mobile");
    let frameType = urlParams.get("frame");
    let sdkStatus = urlParams.get("status");
    let source = urlParams.get("source");

    if (!partnerCode) return;
    frame_store.set(frameType);
    partner_code_store.set(partnerCode);
    mobile_store.set(mobile);
    source_store.set(source);
    agent_code_store.set(agentCode);

    if (sdkStatus)
      document.location.search = `?partnerCode=${partnerCode}${
        frameType ? "&frame=" + frameType : ""
      }${mobile ? "&mobile=" + mobile : ""}${
        agentCode ? "&agentCode=" + agentCode : ""
      }${source ? "&source=" + source : ""}`;

    try {
      const resp = await Get(
        `/freedom/creditLinkConfig?partnerCode=${partnerCode}${
          agentCode ? "&agentCode=" + agentCode : ""
        }${mobile ? "&mobileNumber=" + mobile : ""}`
      );
      if (resp.status) {
        if (resp?.data?.redirectURL) {
          window.location = resp?.data?.redirectURL;
          return;
        }
        Config.update(() => resp?.data);
        themeContext.setTheme({
          ...theme,
          primary: resp?.data?.primaryColor,
          base: resp?.data?.secondaryColor,
          secondary: "#172432",
        });
        client_api_key_store.set(resp?.data.clientApiKey);

        if (resp?.data?.skipLandingPage) {
          route_store.set(ROUTES_CONST.LOGIN);
        } else {
          route_store.set(ROUTES_CONST.HOME);
        }
      } else {
        if (resp?.error === "invalid/expired link") {
          route_store.set(ROUTES_CONST.ERROR_INVALID_LINK);
        } else {
          route_store.set(ROUTES_CONST.ERROR);
        }
      }
    } catch (ex) {
      // Sentry.captureException(ex);
      // console.log(new Error(ex).message);
      route_store.set(ROUTES_CONST.ERROR);
    }
    isLoading = false;
  };

  onMount(() => {
    document.addEventListener("reloadCreditLink", init);
    init();
    return () => document.removeEventListener("reloadCreditLink");
  });
</script>

<div style="width: 100%;height: 100%;">
  <slot />
</div>
