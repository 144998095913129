<script>
  import Button from "../components/Button.svelte";
  import Input from "../components/Input.svelte";
  import { validateMobile } from "../utils";
  import { route_store, mobile_store } from "../stores";
  import { ROUTES_CONST } from "../constants";
  import { onMount } from "svelte";

  onMount(async () => {});
</script>

<style lang="scss">
</style>
