<button on:click {...$$props}>
  <slot />
</button>

<style lang="scss">
  button {
    border: none;
    outline: none;
    color: white;
    width: 100%;
    /* max-width: 500px; */
    background: var(--link-theme-primary);
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    padding: 1rem 1.5rem;
    justify-content: center;
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
    border-radius: 10px;
    cursor: pointer;
    font-weight: 500;
    letter-spacing: 1px;
    box-shadow: 0px 0px 6px -2px rgba(0, 0, 0, 0.37);
    &:hover {
      opacity: 0.85;
      box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.37);
    }
  }
</style>
