<script>
  import {
    MUTHOOT_PROD_PARTNER_CODE,
    MUTHOOT_UAT_PARTNER_CODE,
  } from "../constants";

  export let partner = "";

  // get partner code
  const queryString = new URL(document.location.toString());
  const urlParams = new URLSearchParams(queryString.search);
  let partnerCode = urlParams.get("partnerCode");
</script>

<div class="powered">
  <div
    class={`by ${
      partnerCode === MUTHOOT_PROD_PARTNER_CODE ||
      partnerCode === MUTHOOT_UAT_PARTNER_CODE
        ? "color_white"
        : "color_theme"
    }`}
  >
    {#if !partner
      ?.toLowerCase()
      .includes("truffle") && !(partnerCode === MUTHOOT_PROD_PARTNER_CODE || partnerCode === MUTHOOT_UAT_PARTNER_CODE)}
      Powered By <img src="/images/finBoxDark.svg" alt="FinBox" />
    {:else if !partner?.toLowerCase().includes("truffle")}
      Powered By <img src="/images/FinBox.svg" alt="FinBox" />
    {/if}
  </div>
</div>

<style lang="scss">
  .powered {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .color_white {
    color: #fff;
  }

  .color_theme {
    color: var(--link-theme-secondary);
  }

  .by {
    font-weight: 400;
    font-size: 12px;
    display: flex;
    align-items: center;
    margin: 10px;
    margin-bottom: 5px;
    img {
      height: 15px;
      margin-left: 5px;
      margin-bottom: -2px;
    }
  }
</style>
