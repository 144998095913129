<script>
  import { getContext } from "svelte";
  import BrandNav from "../components/BrandNav.svelte";
  import Loader from "../components/Loader.svelte";
  import { ROUTES_CONST } from "../constants";
  import { route_store } from "../stores";
  import ErrorPage from "./ErrorPage.svelte";
  import Home from "./Home.svelte";
  import Login from "./LOGIN.svelte";
  import SDK from "./SDK.svelte";
  import VerifyOtp from "./VerifyOTP.svelte";

  export let isLoading = true;
  let config = null;
  const configContext = getContext("config");
  configContext((e) => {
    config = e;
  });

  let route;
  route_store.subscribe((value) => {
    route = value;
  });
</script>

<div class="wrapper">
  <Loader {isLoading} />
  {#if route !== ROUTES_CONST.ERROR && route !== ROUTES_CONST.ERROR_INVALID_LINK}
    <BrandNav partnerName={config?.partnerName} />
  {/if}
  {#if !isLoading}
    {#if route == ROUTES_CONST.HOME}
      <Home />
    {:else if route == ROUTES_CONST.LOGIN}
      <Login />
    {:else if route == ROUTES_CONST.VERIFY_OTP}
      <VerifyOtp />
    {:else if route == ROUTES_CONST.SDK}
      <SDK />
    {:else if route == ROUTES_CONST.ERROR_INVALID_LINK}
      <ErrorPage heading="Invalid or Expired link" />
    {:else}
      <ErrorPage />
    {/if}
  {/if}
</div>

<style>
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
  }
</style>
