import { writable } from "svelte/store";
import { ROUTES_CONST } from "../constants";

export const route_store = writable(ROUTES_CONST.HOME);
export const partner_code_store = writable("");
export const agent_code_store = writable("");
export const dsa_id_store = writable("");
export const mobile_store = writable("");
export const frame_store = writable("");
export const credit_link_token_store = writable("");
export const client_api_key_store = writable("");
export const source_store = writable("");
