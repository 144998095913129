<div class="border">
  <div class="dash" />
  <div class="circle" />
  <div class="dash" />
</div>

<style lang="scss">
  .border {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 30px;
    .dash {
      border-top: 1px dashed var(--link-theme-primary);
      height: 1px;
      width: calc(45% - 5px);
    }
    .circle {
      height: 10px;
      width: 10px;
      border-radius: 50%;
      background: var(--link-theme-primary);
    }
  }
</style>
