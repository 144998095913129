<script>
  import { getContext } from "svelte";
  import BrandNav from "../components/BrandNav.svelte";
  import Button from "../components/Button.svelte";
  import Loader from "../components/Loader.svelte";
  import LoanInfoCard from "../components/LoanInfoCard/LoanInfoCard.svelte";
  import {
    MUTHOOT_PROD_PARTNER_CODE,
    MUTHOOT_UAT_PARTNER_CODE,
    ROUTES_CONST,
  } from "../constants";
  import { mobile_store, route_store } from "../stores";
  // get partner code
  const queryString = new URL(document.location.toString());
  const urlParams = new URLSearchParams(queryString.search);
  let partnerCode = urlParams.get("partnerCode");
  let isChecked = false;

  let config = null;
  const configContext = getContext("config");
  configContext((e) => {
    config = e;
    if (partnerCode === "ABFL") {
      config.interest = 18;
      config.eligibleAmount = 1000000;
      config.bureauConsentText =
        "I hereby consent to FinBox being appointed as my authorised representative to receive my Credit Information from CIBIL for the purpose of checking my Credit Score and for credit assessment and facilitation of loan offers.";
    }
  });

  let mobile = "";
  mobile_store.subscribe((value) => {
    mobile = value;
  });

  const onSubmit = () => {
    if (mobile) route_store.set(ROUTES_CONST.VERIFY_OTP);
    else route_store.set(ROUTES_CONST.LOGIN);
  };

  function toggleCheckbox() {
    isChecked = !isChecked;
  }
</script>

<main class="container" id="link-root">
  <LoanInfoCard
    banner={config?.banner}
    eligibleAmount={config?.eligibleAmount}
    interest={config?.interest}
    partnersCode={partnerCode}
  />

  <div class="flex-row">
    <input
      class="cursor"
      type="checkbox"
      bind:checked={isChecked}
      on:input={toggleCheckbox}
    />
    {#if config?.bureauConsentText && !(partnerCode === MUTHOOT_PROD_PARTNER_CODE || partnerCode === MUTHOOT_UAT_PARTNER_CODE)}
      <div style="font-size:12px; text-align: center;">
        {@html config?.bureauConsentText}
      </div>
    {/if}
  </div>
  <div style="margin-top: 10px;" />
  <Button
    style="margin-top:auto; align-self:flex-end; opacity: {!isChecked
      ? 0.4
      : 1}"
    on:click={onSubmit}
    disabled={!isChecked}
  >
    {config?.actionText}
  </Button>
</main>

<style>
  .flex-row {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin: 15px 0;
  }

  .cursor {
    cursor: pointer;
  }
</style>
