<script lang="ts">
  import { getContext } from "svelte";
  import type { Readable, Subscriber, Unsubscriber } from "svelte/store";
  export let eligibleAmount: number = 300000;
  export let interest: number = 0;

  let config = null;
  const configContext: Subscriber<any> = getContext("config");
  configContext((e) => {
    config = e;
  });
  const APPROVAL_ICON = "APPROVAL_ICON";
  const INSTANT_ICON = "INSTANT_ICON";

  let displayTiles = config?.displayTiles || [
    {
      title: "Low Interest",
      description: `Get a credit limit up to ₹5 Lakh*`,
      logoUrl: INSTANT_ICON,
    },
    {
      title: "Fast Approval",
      description: "Interest rates starting at 17% p.a.*",
      logoUrl: APPROVAL_ICON,
    },
    {
      title: "Fast Approval",
      description: "Credit facility available as revolving credit or term loan",
      logoUrl: APPROVAL_ICON,
    },
  ];

  const documentsNeededList = [
    "Bank statement details",
    "Aadhar registered mobile no",
    "Business proof (in case of NO GST registration)",
  ];
</script>

<div class="body">
  <div class="body-card">
    <div class="partners">
      {#if !config?.showLendingPartner}
        <div class="title">by RBI Licensed NBFCs</div>
        <div class="images">
          {#each config?.lendingPartners as lender}
            <img src={lender.logoUrl} alt="" />
          {/each}
        </div>
      {/if}
    </div>
    <div class="blue-card-container">
      <ul>
        {#each displayTiles as d}
          <li class="textc">{d.description}</li>
        {/each}
      </ul>

      <p class="textb">
        Please keep the following details ready for faster application process:
      </p>
      <ul>
        {#each documentsNeededList as doc}
          <li class="textc">{doc}</li>
        {/each}
      </ul>
      <div class="textb">
        *T&C: Credit approval depends on your credit score from credit bureau &
        other documents.
      </div>
    </div>
  </div>
</div>

<style lang="scss">
  .body {
    ul {
      margin-top: 5px;
    }
    .card-title {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      .text {
        line-height: 1.4em;
        font-size: 24px;
        font-weight: 400;
        color: black;
        text-align: center;
        margin: 0;
      }
    }
    .body-card {
      padding: 15px;
      padding-bottom: 0;
      .partners {
        padding-bottom: 10px;
        font-weight: 400;
        border-radius: 10px;
        .title {
          font-size: 14px;
          text-align: center;
          color: #8b8b8b;
        }
        .images {
          display: flex;
          padding-top: 0.2rem;
          justify-content: space-around;
          align-items: center;
          img {
            width: 90px;
          }
        }
      }
    }
    .textc {
      font-size: 14px;
      font-weight: 400;
      color: black;
      margin-top: 5px;
    }
    .textb {
      line-height: 1.4em;
      font-size: 14px;
      font-weight: 400;
      color: black;
      text-align: center;
      margin-top: 10px;
      margin-bottom: 0;
    }
    .blue-card-container {
      display: flex;
      align-items: baseline;
      flex-direction: column;
      justify-content: space-between;

      .blue-card {
        border-radius: 4px;
        background-color: #fafafa;
        width: 42%;
        padding: 9px;
        margin: 10px 0;
        text-align: center;
        .head {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          flex-direction: column;
          justify-content: center;
          -ms-flex-pack: distribute;
          align-items: center;
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
          color: #515f7f;
        }
        .card-text {
          font-size: 12px;
          font-weight: 400;
          color: #6f7a94;
          margin-top: 5px;
        }
        .head-icon {
          padding-right: 4px;
        }
      }
    }
  }
</style>
