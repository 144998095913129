<script lang="ts">
  export let label, sub, value, isError, errorMsg, inputProps, onInput;
  ({ label, value, isError, onInput, ...inputProps } = $$props);
</script>

<div style="width: 100%;">
  <div class="label">
    {label}
  </div>
  {#if sub}
    <div class="sub">
      {sub}
    </div>
  {/if}

  <input bind:value class:error={isError} on:input={onInput} {...inputProps} />
  <div
    style="height: 10px;color:#ff8e8e;font-size:10px;text-align:left;margin-top:5px;margin-bottom: 20px;"
  >
    {#if isError}
      {errorMsg || "invalid"}
    {/if}
  </div>
</div>

<style lang="scss">
  .label {
    color: var(--link-theme-secondary);
    font-weight: 400;
    font-size: 1.4rem;
    text-align: left;
  }

  .sub {
    color: var(--link-theme-tertiary);
    font-weight: 300;
    font-size: 1rem;
    margin-top: 5px;
    text-align: left;
  }

  input {
    margin-top: 20px;
    border: none;
    outline: none;
    color: var(--link-theme-secondary);
    font-size: 1.6rem;
    font-weight: 300;
    padding: 10px;
    box-sizing: border-box;
    text-align: center;
    border-radius: 10px;
    background: var(--link-theme-tileBackground);
    &::placeholder {
      color: var(--link-theme-secondary);
      opacity: 0.3;
      font-weight: 300;
      font-size: 1.2rem;
      letter-spacing: 2px;
    }
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0;
    }
    -webkit-appearance: none;
    -moz-appearance: none;
  }
  .error {
    border: 1px solid #ff8e8e85;
  }
</style>
