<script lang="ts">
  import Card from "../Card.svelte";
  import { formatAmount } from "../../utils";
  import LoanIcon from "../../svelteIcons/home/LoanIcon.svelte";
  import ApprovalIcon from "../../svelteIcons/home/ApprovalIcon.svelte";
  import InstantIcon from "../../svelteIcons/home/InstantIcon.svelte";
  import { getContext } from "svelte";
  import type { Readable, Subscriber, Unsubscriber } from "svelte/store";
  export let eligibleAmount: number = 300000;
  export let interest: number = 0;

  let config = null;
  const configContext: Subscriber<any> = getContext("config");
  configContext((e) => {
    config = e;
  });
  const APPROVAL_ICON = "APPROVAL_ICON";
  const INSTANT_ICON = "INSTANT_ICON";
  let displayTiles = config?.displayTiles || [
    {
      title: "Low Interest",
      description: `Interest are as low as ${interest}% per annum`,
      logoUrl: INSTANT_ICON,
    },
    {
      title: "Fast Approval",
      description: "Instant approval in 3 steps.",
      logoUrl: APPROVAL_ICON,
    },
  ];
</script>

<!-- 
<Card style="display:flex;align-items:center;flex-direction:column">
  <img
    src="/images/icons/home/loan_head.svg"
    alt="loan head"
    class="head-img"
  />
  <div class="quick-loan">
    Get a quick loan upto<br />{formatAmount(eligibleAmount)}
  </div>
</Card>

<div class="col">
  <Card>
    <div>
      <img src="/images/icons/home/interest.svg" alt="instant loan" />
      <div class="card-head">Lowest Interest rate</div>
      <div class="card-text">Interest are as low as 18%* per annum</div>
    </div>
  </Card>
  <Card style="margin-top:10px">
    <div>
      <img src="/images/icons/home/docs.svg" alt="instant loan" />
      <div class="card-head">Easy Collection of Documents</div>
      <div class="card-text">
        You only have to upload PAN and Address proof.
      </div>
    </div>
  </Card>
</div> -->

<div class="body">
  <div class="card-title">
    <LoanIcon />
    <h2 class="text">
      {config?.title || "Quick loan upto"}
      {" "}
      {formatAmount(eligibleAmount)}
    </h2>
  </div>

  <div class="body-card">
    <div class="partners">
      {#if config?.showLendingPartner}
        <div class="title">by RBI Licensed NBFCs</div>
        <div class="images m-top">
          {#each config?.lendingPartners as lender}
            <img src={lender.logoUrl} alt="" />
          {/each}
        </div>
      {/if}
    </div>
    <div class="blue-card-container">
      {#each displayTiles as d}
        <div class="blue-card">
          <div class="head">
            <div class="head-icon">
              {#if d.logoUrl === INSTANT_ICON}
                <InstantIcon />
              {:else if d.logoUrl === APPROVAL_ICON}
                <ApprovalIcon />
              {:else}
                <img src={d.logoUrl} alt="" />
              {/if}
            </div>
            <div>{d.title}</div>
          </div>
          <div class="card-text">{d.description}</div>
        </div>
      {/each}
    </div>
  </div>
</div>

<style lang="scss">
  .body {
    .card-title {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      .text {
        line-height: 1.4em;
        font-size: 24px;
        font-weight: 400;
        color: black;
        text-align: center;
        margin: 0;
      }
    }
    .body-card {
      padding: 15px;
      padding-bottom: 0;
      .partners {
        padding-bottom: 10px;
        font-weight: 400;
        border-radius: 10px;
        .title {
          font-size: 14px;
          text-align: center;
          color: #8b8b8b;
        }
        .images {
          display: flex;
          padding-top: 0.2rem;
          justify-content: space-around;
          align-items: center;
          img {
            width: 90px;
          }
        }
      }
    }

    .m-top {
      margin-top: 10px;
    }
    .blue-card-container {
      display: flex;
      justify-content: space-between;
      .blue-card {
        border-radius: 4px;
        background-color: #fafafa;
        width: 42%;
        padding: 9px;
        margin: 10px 0;
        text-align: center;
        .head {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          flex-direction: column;
          justify-content: center;
          -ms-flex-pack: distribute;
          align-items: center;
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
          color: #515f7f;
        }
        .card-text {
          font-size: 12px;
          font-weight: 400;
          color: #6f7a94;
          margin-top: 5px;
        }
        .head-icon {
          padding-right: 4px;
        }
      }
    }
  }
</style>
