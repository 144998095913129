<script lang="ts">
  export let isLoading: boolean;
</script>

{#if isLoading}
  <div class="loader">
    <img src="/images/loading.gif" alt="loading.." />
  </div>
{/if}

<style lang="scss">
  .loader {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 30%;
    }
  }
</style>
