<script>
  import Button from "../components/Button.svelte";
  import DottedLine from "../components/DottedLine.svelte";
  import Input from "../components/Input.svelte";
  import { ROUTES_CONST } from "../constants";
  import { mobile_store, route_store } from "../stores";
  import { validateMobile } from "../utils";

  let mobile = "";
  let isMobile = true;

  $: !isMobile && validateMobile(mobile) && (isMobile = true);

  const handleSubmit = () => {
    if (!validateMobile(mobile)) {
      isMobile = false;
      return;
    }
    isMobile = true;
    mobile_store.set(mobile);
    route_store.set(ROUTES_CONST.VERIFY_OTP);
  };

</script>

<main class="container" id="link-root">

  <!-- <div style="margin-top:20%" /> -->

  <DottedLine />

  <Input
    style="width: 100%;"
    label="Enter your phone no."
    isError={!isMobile}
    placeholder="eg: 8888888888"
    errorMsg="Invalid mobile number"
    autofocus
    type="tel"
    maxLength="10"
    pattern="\d*"
    bind:value={mobile}
    onInput={(e) => {
      const isNumber = !isNaN(Number(e.target.value));
      const hasMathSymbols = /[\.e]/g.test(e.target.value);
      if(isNumber && !hasMathSymbols) {
        return mobile
      } else {
        mobile = mobile.slice(0, mobile.length - 1);
        return mobile
      }
    }}
  />

  <Button
    style="margin-top:auto;align-self:center;margin-bottom:10%"
    on:click={handleSubmit}
  >
    Verify Mobile
  </Button>
</main>
