import App from "./App.svelte";
import * as Sentry from "@sentry/svelte";
import { BrowserTracing } from "@sentry/tracing";
import packageJSON from "../package.json";
// Sentry enabled for all env other than dev
if (!/DEV+/.test(process.env.ENV)) {
    Sentry.init({
        dsn: "https://6e808c89d47e4da2a3b554a0f884ba41@o82232.ingest.sentry.io/4504003411574784",
        integrations: [new BrowserTracing()],
        tracesSampleRate: 0,
        environment: process.env.ENV,
        release: packageJSON.name + "@" + packageJSON.version,
        ignoreErrors: [
            "getReadModeConfig",
            "getReadModeExtract",
            "getReadModeRender",
            "processRandomSelector",
        ],
    });
}
console.log(packageJSON.name + "@" + packageJSON.version);
const app = new App({
    target: document.body,
    props: {},
});
export default app;
