import { client_api_key_store } from "./stores";

let clientApiKey = "";

client_api_key_store.subscribe((value) => {
  clientApiKey = value;
});

export const camelToHuman = (string) => {
  const start =
    string.charAt(0).toUpperCase() == string.charAt(0)
      ? ""
      : string.charAt(0).toUpperCase();
  return (
    start +
    string
      .replace(/([A-Z]+)/g, " $1")
      .replace(/([A-Z][a-z])/g, " $1")
      .slice(1)
  );
};

export const formatAmount = (amount) => {
  if (!amount && amount !== 0) return "";
  let x = amount.toString();
  let afterPoint = "";
  if (x.indexOf(".") > 0) afterPoint = x.substring(x.indexOf("."), x.length);
  x = Math.floor(x);
  x = x.toString();
  let lastThree = x.substring(x.length - 3);
  let otherNumbers = x.substring(0, x.length - 3);
  if (otherNumbers != "") lastThree = "," + lastThree;
  let res =
    otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree + afterPoint;
  return "₹ " + res;
};

export const sleep = (ms) => new Promise((res) => setTimeout(res, ms));

export const Post = async (path, token, apiKey, data) => {
  let headers = { "Content-Type": "application/json" };
  if (apiKey) {
    headers["x-api-key"] = apiKey;
  } else {
    headers["x-api-key"] = clientApiKey;
  }
  if (token) headers["token"] = token;

  var requestOptions = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(data),
    redirect: "follow",
  };

  let json;
  try {
    const response = await fetch(
      `${process.env.BASE_URL}${path}`,
      requestOptions
    );
    json = await response.json();
  } catch (error) {
    json = { data: null, status: false, error: Error(error).message };
  }
  return json;
};

const _get = async (path, token) => {
  let headers = { "Content-Type": "application/json" };
  if (token) headers["token"] = token;
  var requestOptions = {
    method: "GET",
    headers: headers,
    redirect: "follow",
  };

  let json = {
    statusCode: 500,
    status: false,
    error: "",
    data: {},
  };

  try {
    const response = await fetch(
      `${process.env.BASE_URL}${path}`,
      requestOptions
    );
    json = await response?.json();
    json.statusCode = response.status;
  } catch (error) {
    json.error = new Error(error).message;
  }
  return json;
};

export const Get = async (path, token, isRetry = true, retryCount = 3) => {
  let resp = null;

  // If retry is disabled
  if (!isRetry) {
    resp = await _get(path, token);
    return resp;
  }

  // Retry n times till success
  let i = 1; // only for logs
  while (retryCount > 0) {
    resp = await _get(path, token);
    const attemptLog = {
      path,
      attempt: 1,
    };
    if (resp?.error) {
      attemptLog["error"] = resp?.error;
    }
    console.log(attemptLog);
    if (resp.status || resp.error === "invalid/expired link") {
      return resp;
    }
    const jitter = Math.floor(Math.random() * 500) + 300; // some randomness in the retry delay
    await sleep(1000 + jitter);
    i++;
    retryCount--;
  }
  console.log("retries failed");
  // Sentry.captureException(resp?.error);
  return resp;
};

export const validateEmail = (email) => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email.toLowerCase());
};

export const validatePinCode = (pincode) => {
  const re = /^[1-9][0-9]{5}$/;
  return re.test(pincode.toLowerCase());
};

export const validateMobile = (mobile) => {
  if (!mobile) return false;
  const re = /^[6-9]\d{9}$/;
  return mobile.length === 10 && !isNaN(mobile) && re.test(mobile);
};

export const validateName = (name) => {
  const re = /^[A-Za-z\s]+$/;
  return re.test(name);
};

export const validatePan = (panVal) => {
  var regpan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
  return regpan.test(panVal);
};

export const validateBusinessPan = (panVal) => {
  var regpan =
    /^[a-zA-Z]{3}[ABCFGHJLTabcfghjlt]{1}[a-zA-Z]{1}[0-9]{4}[a-zA-Z]{1}$/;
  return regpan.test(panVal);
};

export const validateDate = (date) => {
  return /^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/.test(date);
};

export const downloadLink = (link) => {
  let a = document.createElement("a");
  document.body.appendChild(a);
  a.style = "display: none";
  a.href = link;
  a.download = true;
  a.click();
  document.body.removeChild(a);
};

export const blobCreationFromURI = (dataurl) => {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
};

export const debounce = (func, delay) => {
  let debounceTimer;
  return (...args) => {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func(...args), delay);
  };
};

export const secToTime = (SECONDS) => {
  var date = new Date(null);
  date.setSeconds(SECONDS); // specify value for SECONDS here
  var result = date.toISOString().substr(14, 5);
  return result;
};
