<script>
  import { getContext } from "svelte";
  import Powered from "../components/Powered.svelte";
  import {
    MUTHOOT_PROD_PARTNER_CODE,
    MUTHOOT_UAT_PARTNER_CODE,
  } from "../constants";

  let config = null;
  const configContext = getContext("config");
  configContext((e) => {
    config = e;
  });

  // get partner code
  const queryString = new URL(document.location.toString());
  const urlParams = new URLSearchParams(queryString.search);
  let partnerCode = urlParams.get("partnerCode");

  export let partnerName;
</script>

<div
  class={`${
    partnerCode === MUTHOOT_PROD_PARTNER_CODE ||
    partnerCode == MUTHOOT_UAT_PARTNER_CODE
      ? "header-custom"
      : "header"
  }`}
>
  <div class="header-logo">
    <img src={config?.partnerLogoURL} style="width: 60%;" alt="partner-logo" />
    <div class="header-name">{partnerName}</div>
    <Powered />
  </div>
</div>

<style lang="scss">
  .header {
    width: 100%;
    height: max-content;
    padding: 1.5rem 0;
    border-bottom-left-radius: 50% 30%;
    border-bottom-right-radius: 50% 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fafafa;
    .header-name {
      text-align: center;
      margin: 0.8rem 0;
      font-size: 1rem;
      font-weight: 600;
      @media screen and (min-width: 600px) {
        font-size: 1.1rem;
      }
      @media screen and (min-width: 900px) {
        font-size: 1.2rem;
      }
    }
    .header-logo {
      height: auto;
      width: 55%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: var(--link-theme-secondary);

      @media screen and (min-width: 600px) {
        width: 35%;
      }
      @media screen and (min-width: 900px) {
        width: 30%;
      }
    }
  }

  .header-custom {
    width: 100%;
    height: inherit;
    padding: 1.5rem 0;
    border-bottom-left-radius: 70% 30%;
    border-bottom-right-radius: 70% 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--link-theme-base);
    .header-name {
      text-align: center;
      margin: 0.8rem 0;
      font-size: 1.5rem;
      font-weight: 600;
      color: white;
      @media screen and (min-width: 600px) {
        font-size: 1.6rem;
      }
      @media screen and (min-width: 900px) {
        font-size: 1.7rem;
      }
    }
    .header-logo {
      height: auto;
      width: 75%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: var(--link-theme-secondary);

      @media screen and (min-width: 600px) {
        width: 35%;
      }
      @media screen and (min-width: 900px) {
        width: 30%;
      }
    }
  }
</style>
