<script>
  import Button from "../components/Button.svelte";
  export let heading = "Something went wrong";
  let ref;
  const handleClick = (e) => {
    e.preventDefault();
    const event = new Event("reloadCreditLink", {
      bubbles: true,
      composed: true,
    });
    ref.dispatchEvent(event);
  };
</script>

<main class="container" id="link-root" bind:this={ref}>
  <img src="/images/icons/common/error.png" alt="error" />
  <h1
    style="align-self: center;color:var(--link-theme-tertiary);margin:0;text-align: center;"
  >
    {heading}
  </h1>
  <p style="font-size: 1.3rem;align-self:center;">Please try again</p>
  <Button style="margin-top:35%" on:click={handleClick}>Try Again</Button>
</main>

<style lang="scss">
  img {
    width: 60%;
    margin: 3rem auto;
    margin-top: 35%;

    @media screen and (min-width: 550px) {
      width: 40%;
      margin-top: 30%;
    }
    @media screen and (min-width: 700px) {
      width: 40%;
      margin-top: 20%;
    }
  }
</style>
