<script lang="ts">
  import {
    MUTHOOT_PROD_PARTNER_CODE,
    MUTHOOT_UAT_PARTNER_CODE,
  } from "../../constants";
  import DottedLine from "../DottedLine.svelte";
  import FreshCustom from "../FreshCustom.svelte";
  import Cancelled from "./Cancelled.svelte";
  import Closed from "./Closed.svelte";

  import Due from "./Due.svelte";

  import Fresh from "./Fresh.svelte";
  import Rejected from "./Rejected.svelte";

  // export let banner: string = "";
  export let eligibleAmount: number = 300000;
  export let interest: number;
  export let partnersCode: string;
</script>

<!-- <div class="info">
  {#if banner === "REJECTED"}
    <DottedLine />
    <Rejected />
  {:else if banner === "CLOSED"}
    <DottedLine />
    <Closed />
  {:else if banner === "DUE"}
    <DottedLine />
    <Due />
  {:else if banner === "OVERDUE"}
    <DottedLine />
    <Due isOverdue={true} />
  {:else if banner === "CANCELLED"}
    <DottedLine />
    <Cancelled />
  {:else}
    <Fresh {eligibleAmount} {interest} />
  {/if}
</div> -->

<div class="info">
  {#if partnersCode === MUTHOOT_PROD_PARTNER_CODE || partnersCode === MUTHOOT_UAT_PARTNER_CODE}
    <FreshCustom {eligibleAmount} {interest} />
  {:else}
    <Fresh {eligibleAmount} {interest} />
  {/if}
</div>

<style lang="scss">
  .info {
    width: 100%;
    height: 100%;
    letter-spacing: 1px;
    color: var(--link-theme-secondary);
  }
</style>
