export const ROUTES_CONST = {
  HOME: "HOME",
  LOGIN: "LOGIN",
  VERIFY_OTP: "VERIFY_OTP",
  SDK: "SDK",
  ERROR: "ERROR",
  ERROR_INVALID_LINK: "ERROR_INVALID_LINK",
};

export const MUTHOOT_UAT_PARTNER_CODE = "MUCL_EX29";
export const MUTHOOT_PROD_PARTNER_CODE = "ES_RLVPYC";
