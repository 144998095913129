<script>
  import { setContext, onMount } from "svelte";
  import { writable } from "svelte/store";

  let _theme = {
    tertiary: "#4D4F55",
    secondary: "#202227",
    primary: "#0075FF",
    tileBackground: "#eaf0f5",
    errorColor: "#FF5364",
    warningBackground: "#FDF0EF",
  };

  const Theme = writable(_theme);

  setContext("theme", {
    theme: Theme,
    setTheme: (t) => {
      Theme.update((t) => t);

      try {
        localStorage?.setItem("link-theme", JSON.stringify(t));
      } catch (error) {
        //
      }

      setRootColors(t);
    },
  });

  const setRootColors = (theme) => {
    if (!theme) return;
    for (let [prop, color] of Object.entries(theme)) {
      let varString = `--link-theme-${prop}`;
      document.documentElement.style.setProperty(varString, color);
    }
  };

  onMount(() => {
    setRootColors(_theme);
    try {
      const t = JSON.parse(localStorage.getItem("link-theme"));
      setRootColors(t);
    } catch (error) {
      //
    }
  });
</script>

<slot />
